.campaign {
    padding: 24px 0;

    @include mq($not-mobile-tablet-pt) {
        padding: 44px 0;
    }
}

.campaign__wrapper {
    background: linear-gradient(135deg, #ffaa30 0%, #fe7000 80.21%);
    margin: 0 auto;
    max-width: 2000px;
    overflow: hidden;
    padding: 0 16px;
    position: relative;

    @include mq($not-mobile-tablet-pt) {
        padding: 0 20px;
    }
}

.campaign__slider {
    .swiper-slide {
        margin: 16px 0;
        height: auto;
        min-width: 343px;
        width: 100%;
        max-width: 432px;

        @include mq($not-mobile-tablet-pt) {
            margin: 20px 0;
            width: 40vw;
            max-width: 100%;
        }
    }

    .swiper-slide--first {
        @include mq($not-mobile-tablet-pt) {
            width: 80vw;
        }
    }

    .swiper-slide--last {
        @include mq($not-mobile-tablet-pt) {
            width: 60vw;
        }
    }
}

.campaign__content {
    align-items: flex-end;
    background-position: center;
    background-size: cover;
    display: flex;
    height: 100%;
    min-height: 648px;
    position: relative;

    &::after {
        background: linear-gradient(180.01deg, rgb(0 0 0 / 50%) 9.61%, rgb(0 0 0 / 0%) 15.57%, rgb(0 0 0 / 0%) 63.97%, rgb(0 0 0 / 20%) 91.08%);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;

        @include mq($not-mobile-tablet-pt) {
            background: linear-gradient(180deg, rgb(0 0 0 / 20%) 22.29%, rgb(0 0 0 / 0%) 34.04%, rgb(0 0 0 / 0%) 51.09%, rgb(0 0 0 / 20%) 60.22%);
        }
    }

    @include mq($not-mobile-tablet-pt) {
        min-height: 642px;

        .swiper-slide--first &,
        .swiper-slide--last & {
            align-items: flex-start;
            padding: 48px 44px;
        }
    }
}

.campaign__content-inner {
    color: $white;
    position: relative;
    width: 100%;
    z-index: 1;

    @include mq($not-mobile-tablet-pt) {
        height: 100%;
    }
}

.campaign__image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }
}

.campaign__slide-title,
.campaign__slide-subtitle {
    font-size: 32px;
    line-height: 1.2;
    max-width: 90%;

    @include mq($not-mobile-tablet-pt) {
        font-size: 48px;
        max-width: 360px;
    }
}

.campaign__slide-title {
    margin-bottom: 0;

    @include mq($not-mobile-tablet-pt) {
        margin-bottom: 15px;
    }
}

.campaign__slide-subtitle {
    margin-bottom: 12px;
    -webkit-text-stroke: 1px $white;
    color: transparent;
}

.campaign__slide-outro-title {
    font-family: $heading-pro-double-regular;
    font-size: 24px;
    line-height: 1.2;
    max-width: 90%;
    margin-bottom: 3px;
}

.campaign__slide-description {
    font-size: 14px;
    line-height: 1.3;
    margin-bottom: 10px;
}

.campaign__text-wrapper {
    align-items: center;
    display: flex;
    height: 175px;
    width: 100%;
}

.campaign__text {
    line-height: 1.3;
    margin: 0 auto;
    max-width: 340px;
    padding: 0 20px 20px;
    position: relative;
    text-align: center;
    z-index: 1;
}

.campaign__text-content {
    display: flex;
    flex-direction: column;
    height: 182px;
    justify-content: flex-end;
    padding: 0 20px 20px;

    @include mq($not-mobile-tablet-pt) {
        align-items: flex-start;
        height: 100%;
        justify-content: flex-start;
        padding: 0;
    }
}

.campaign__text-content--outro {
    @include mq($not-mobile-tablet-pt) {
        justify-content: flex-end;
        max-width: 400px;

        .campaign__btn {
            margin-top: 0;
        }
    }
}

.campaign__description {
    background: $white;
    border-radius: 6px;
    box-decoration-break: clone;
    display: inline;
    font-size: 14px;
    line-height: 26px;
    padding: 5px 10px;
}

.campaign__btn {
    width: 100%;

    @include mq($not-mobile-tablet-pt) {
        margin-top: auto;
        width: auto;
    }
}

.campaign__slider-navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: static;

    @include mq($not-mobile-tablet-pt) {
        justify-content: center;
        position: static;
    }

    .swiper-button-disabled {
        opacity: 0.5;

        @include mq($not-mobile-tablet-pt) {
            opacity: 0;
        }
    }
}

.campaign__slider-pagination {
    position: absolute;
    top: 16px;
    left: 16px;
    display: flex;
    right: 0;
    z-index: 2;
    padding: 20px 20px 16px;
    background: linear-gradient(180deg, rgb(0 0 0 / 50%) 18.84%, rgb(0 0 0 / 0%) 97.99%, rgb(0 0 0 / 0%) 402.8%, rgb(0 0 0 / 20%) 573.49%);

    @include mq($mobile-pt-l) {
        max-width: 430px;
    }

    @include mq($not-mobile-tablet-pt) {
        display: none;
    }

    .swiper-pagination-bullet {
        width: 50%;
        height: 6px;
        flex: 1 1 auto;
        display: inline-block;
        border-radius: 3px;
        background: $white-smoke;
        opacity: 1;
        margin: auto 11px;
        transition: all 0.2s ease-in-out;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: $safety-orange;
        padding: 3px;
    }
}

.campaign__slider-arrow {
    cursor: pointer;
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 25%);
    height: 36px;
    padding: 4px;
    position: absolute;
    text-align: center;
    bottom: 220px;
    width: 36px;
    z-index: 2;

    @include mq($not-mobile-tablet-pt) {
        bottom: auto;
        height: 56px;
        padding: 14px;
        top: 48%;
        width: 56px;
        transition: scale 0.1s ease-in-out;

        &:hover {
            scale: 1.1;
        }
    }
}

.campaign__slider-arrow--prev {
    left: 37px;
    margin-right: 9px;

    &::before {
        @include iconfont-styles;

        content: svg(swiper-arrow);
        color: $nero;
        display: inline-block;
        font-size: 16px;
        height: 16px;
        line-height: initial;
        position: relative;
        transform: scale(-1, 1);
        vertical-align: middle;
        width: 16px;
    }
}

.campaign__slider-arrow--next {
    margin-left: 9px;
    left: 71px;

    &::after {
        @include iconfont-styles;

        content: svg(swiper-arrow);
        display: inline-block;
        position: relative;
        font-size: 16px;
        vertical-align: middle;
        line-height: initial;
        color: $nero;
        width: 16px;
        height: 16px;
    }

    @include mq($not-mobile-tablet-pt) {
        left: auto;
        right: 50px;
    }
}
