/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.campaign {
  padding: 24px 0;
}
@media only screen and (min-width: 901px) {
  .campaign {
    padding: 44px 0;
  }
}

.campaign__wrapper {
  background: linear-gradient(135deg, #ffaa30 0%, #fe7000 80.21%);
  margin: 0 auto;
  max-width: 2000px;
  overflow: hidden;
  padding: 0 16px;
  position: relative;
}
@media only screen and (min-width: 901px) {
  .campaign__wrapper {
    padding: 0 20px;
  }
}

.campaign__slider .swiper-slide {
  margin: 16px 0;
  height: auto;
  min-width: 343px;
  width: 100%;
  max-width: 432px;
}
@media only screen and (min-width: 901px) {
  .campaign__slider .swiper-slide {
    margin: 20px 0;
    width: 40vw;
    max-width: 100%;
  }
}
@media only screen and (min-width: 901px) {
  .campaign__slider .swiper-slide--first {
    width: 80vw;
  }
}
@media only screen and (min-width: 901px) {
  .campaign__slider .swiper-slide--last {
    width: 60vw;
  }
}

.campaign__content {
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  display: flex;
  height: 100%;
  min-height: 648px;
  position: relative;
}
.campaign__content::after {
  background: linear-gradient(180.01deg, rgba(0, 0, 0, 0.5) 9.61%, rgba(0, 0, 0, 0) 15.57%, rgba(0, 0, 0, 0) 63.97%, rgba(0, 0, 0, 0.2) 91.08%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}
@media only screen and (min-width: 901px) {
  .campaign__content::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 22.29%, rgba(0, 0, 0, 0) 34.04%, rgba(0, 0, 0, 0) 51.09%, rgba(0, 0, 0, 0.2) 60.22%);
  }
}
@media only screen and (min-width: 901px) {
  .campaign__content {
    min-height: 642px;
  }
  .swiper-slide--first .campaign__content, .swiper-slide--last .campaign__content {
    align-items: flex-start;
    padding: 48px 44px;
  }
}

.campaign__content-inner {
  color: #fff;
  position: relative;
  width: 100%;
  z-index: 1;
}
@media only screen and (min-width: 901px) {
  .campaign__content-inner {
    height: 100%;
  }
}

.campaign__image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.campaign__image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.campaign__slide-title,
.campaign__slide-subtitle {
  font-size: 32px;
  line-height: 1.2;
  max-width: 90%;
}
@media only screen and (min-width: 901px) {
  .campaign__slide-title,
  .campaign__slide-subtitle {
    font-size: 48px;
    max-width: 360px;
  }
}

.campaign__slide-title {
  margin-bottom: 0;
}
@media only screen and (min-width: 901px) {
  .campaign__slide-title {
    margin-bottom: 15px;
  }
}

.campaign__slide-subtitle {
  margin-bottom: 12px;
  -webkit-text-stroke: 1px #fff;
  color: transparent;
}

.campaign__slide-outro-title {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-size: 24px;
  line-height: 1.2;
  max-width: 90%;
  margin-bottom: 3px;
}

.campaign__slide-description {
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 10px;
}

.campaign__text-wrapper {
  align-items: center;
  display: flex;
  height: 175px;
  width: 100%;
}

.campaign__text {
  line-height: 1.3;
  margin: 0 auto;
  max-width: 340px;
  padding: 0 20px 20px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.campaign__text-content {
  display: flex;
  flex-direction: column;
  height: 182px;
  justify-content: flex-end;
  padding: 0 20px 20px;
}
@media only screen and (min-width: 901px) {
  .campaign__text-content {
    align-items: flex-start;
    height: 100%;
    justify-content: flex-start;
    padding: 0;
  }
}

@media only screen and (min-width: 901px) {
  .campaign__text-content--outro {
    justify-content: flex-end;
    max-width: 400px;
  }
  .campaign__text-content--outro .campaign__btn {
    margin-top: 0;
  }
}

.campaign__description {
  background: #fff;
  border-radius: 6px;
  box-decoration-break: clone;
  display: inline;
  font-size: 14px;
  line-height: 26px;
  padding: 5px 10px;
}

.campaign__btn {
  width: 100%;
}
@media only screen and (min-width: 901px) {
  .campaign__btn {
    margin-top: auto;
    width: auto;
  }
}

.campaign__slider-navigation {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: static;
}
@media only screen and (min-width: 901px) {
  .campaign__slider-navigation {
    justify-content: center;
    position: static;
  }
}
.campaign__slider-navigation .swiper-button-disabled {
  opacity: 0.5;
}
@media only screen and (min-width: 901px) {
  .campaign__slider-navigation .swiper-button-disabled {
    opacity: 0;
  }
}

.campaign__slider-pagination {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  right: 0;
  z-index: 2;
  padding: 20px 20px 16px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 18.84%, rgba(0, 0, 0, 0) 97.99%, rgba(0, 0, 0, 0) 402.8%, rgba(0, 0, 0, 0.2) 573.49%);
}
@media only screen and (max-width: 550px) {
  .campaign__slider-pagination {
    max-width: 430px;
  }
}
@media only screen and (min-width: 901px) {
  .campaign__slider-pagination {
    display: none;
  }
}
.campaign__slider-pagination .swiper-pagination-bullet {
  width: 50%;
  height: 6px;
  flex: 1 1 auto;
  display: inline-block;
  border-radius: 3px;
  background: #efefef;
  opacity: 1;
  margin: auto 11px;
  transition: all 0.2s ease-in-out;
}
.campaign__slider-pagination .swiper-pagination-bullet:first-child {
  margin-left: 0;
}
.campaign__slider-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.campaign__slider-pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fe7000;
  padding: 3px;
}

.campaign__slider-arrow {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  height: 36px;
  padding: 4px;
  position: absolute;
  text-align: center;
  bottom: 220px;
  width: 36px;
  z-index: 2;
}
@media only screen and (min-width: 901px) {
  .campaign__slider-arrow {
    bottom: auto;
    height: 56px;
    padding: 14px;
    top: 48%;
    width: 56px;
    transition: scale 0.1s ease-in-out;
  }
  .campaign__slider-arrow:hover {
    scale: 1.1;
  }
}

.campaign__slider-arrow--prev {
  left: 37px;
  margin-right: 9px;
}
.campaign__slider-arrow--prev::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  color: #2d2d2d;
  display: inline-block;
  font-size: 16px;
  height: 16px;
  line-height: initial;
  position: relative;
  transform: scale(-1, 1);
  vertical-align: middle;
  width: 16px;
}

.campaign__slider-arrow--next {
  margin-left: 9px;
  left: 71px;
}
.campaign__slider-arrow--next::after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  display: inline-block;
  position: relative;
  font-size: 16px;
  vertical-align: middle;
  line-height: initial;
  color: #2d2d2d;
  width: 16px;
  height: 16px;
}
@media only screen and (min-width: 901px) {
  .campaign__slider-arrow--next {
    left: auto;
    right: 50px;
  }
}